<template>
  <div class="mobileCurtain">
     <div
      style="min-height:0.8rem;background-color:#414141"
      :style="{display:showFixed?'block':'none'}"
    ></div>
    <div class="headers" :style="{position:showFixed?'fixed':'',top:showFixed?'0':''}">
      <van-dropdown-menu>
        <van-dropdown-item title="窗帘电机" ref="item">
         <div class="li" @click="change('/mobile/ProGateway')">
            <a>超级主机</a>
          </div>
          <div class="li" @click="change('/mobile/ProPanel')">
            <a>智能面板</a>
          </div>
          <div class="li" @click="change('/mobile/ProSinglePoint')">
            <a>单点</a>
          </div>
          <div class="li" @click="change('/mobile/ProOther')">
            <a>其他控制</a>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="panel">
      <div class="panelLi"  @click="change('/mobile/ProCurtain')">
        <img src="../../assets/mobile/product/curtain1.png" class="panelImg" />
        <div class="panelName">窗帘电机</div>
      </div>

    
    </div>
  </div>
</template>

<script>
export default {
   data() {
    return {
      scrollTop: 0,
      showFixed: false,
      n: ""
    };
  },
  created() {
    this.handleScroll();
    var that = this;
    window.screenWidth = document.body.clientWidth;
    that.n = window.screenWidth / 7.5;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.n = window.screenWidth / 7.5;
      })();
    };
  },
  methods: {
    change(href) {
      this.$router.push(href);
    },
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    }
  },
  watch: {
    // 监测滚动距离固定导航条
    scrollTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0.96 * this.n) {
        this.showFixed = true;
      } else {
        this.showFixed = false;
      }
    }
  }
};
</script>
<style scoped lang="less">
/deep/.van-dropdown-menu__bar {
  background-color: #ffffff;
  height: 0.8rem;
  box-shadow: 0px 0px 0px #fff;
}
/deep/ .van-popup {
  background-color: #f8f8f8;
}
/deep/ .van-ellipsis {
  color: #333;
  font-size: 0.24rem;
  line-height: 0.24rem;
  font-weight: 400;
}
/deep/.van-dropdown-menu__item {
  padding: 0px 15px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  text-align: left;
  opacity: 1;

  .van-dropdown-menu__title {
    width: 100%;
    padding: 0;
    margin-right: 10px;
    color: #999;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      border: 0;
      opacity: 1;
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      transform: rotate(135deg);
      transition: all 0.3s;
      width: 8px;
      height: 8px;
      margin-top: 0px;
    }
  }
  .van-dropdown-menu__title--active {
    color: #333;
    &::after {
      transform: rotate(-45deg);
      top: 4px;
    }
  }
}
.headers {
  overflow: visible !important;
   border-bottom: 0.02rem solid #F8F8F8;
   width: 100%;
    z-index: 999;
  .li {
    // width: 100%;
    margin: 0px 15px;
    height: 40px;
    border-bottom: 1px solid #999999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:last-of-type {
      border-bottom: 0px;
    }
    a {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333;
    }
  }
}

// 页面内容
.panel {
  background-color: #fff;
  padding: 0 0.3rem;
  padding-top: 0.3rem;
  padding-bottom: 5.5rem;
  display: flex;
  flex-wrap: wrap;
  .panelLi {
    display: flex;
    flex-direction: column;
    width: calc((100% - 0.3rem) / 2);
    height: auto;
    margin-right: 0.3rem;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    .panelImg {
       display: block;
      width: 100%;
    }
    .panelName {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      margin-top: 0.2rem;
    }
  }
}
</style>